import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Rose({ styles, editing, ...props }) {
  const Elements = useComponents()

  const svg_color = styles.forElement("content_container").backgroundColor
  const checkboxes_color = styles.forElement("form_heading").color

  const linkDefaults = {
    settings: {
      radius: "50px",
      shadow: "none",
      columns: 1,
      backgroundColor: "#ffffff",
      textColor: "#6b7790",
      iconBorder: "0",
      iconBorderColor: "#ffffff",
      fontSize: "16",
      fontWeight: "normal",
      iconColorStyle: "custom",
      iconColor: "#f7c86f",
      linkBorder: "0",
      linkBorderColor: "#f7c86f"
    },
    children: [
      {
        type: "Link",
        url: "https://spotify.com",
        text: "Listen on Spotify",
        icon: "spotify",
        hasIcon: "social"
      },
      {
        type: "Link",
        url: "https://apple.com",
        text: "Listen on iTunes",
        icon: "itunes-note",
        hasIcon: "social"
      },
      {
        type: "Link",
        url: "https://soundcloud.com",
        text: "Listen on Soundcloud",
        icon: "soundcloud",
        hasIcon: "social"
      }
    ]
  }
  const galleryDefaults = {
    settings: {
      layout: "grid",
      crop: "square",
      radius: "6px",
      columns: 1,
      captionSize: "15",
      captionColor: "#aaaaaa",
      captionWeight: "normal"
    },
    children: [
      {
        type: "Image",
        src:
          "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/fpXr5PGZBGdYjNWG5s11xA",
        alt: "Adieu"
      }
    ]
  }

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        className={`${editing ? "formkit-page-editing" : ""} formkit-wrapper`}
      >
        <div className={`${editing ? "" : "formkit-sticky"} formkit-relative`}>
          <div className="formkit-header-container" role="banner">
            <div className="formkit-header" style={styles.forElement("header")}>
              <Elements.Image
                className="formkit-avatar"
                name="avatar"
                defaults={{
                  src:
                    "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/7a3qbMj1rfWk7UaWWg3gbY?w=300"
                }}
                size={{ w: 100, h: 100 }}
                aspectRatio={1}
                shape="circle"
              />
              <Elements.Heading
                className="formkit-heading"
                name="heading"
                defaults={{
                  content: "Adieu"
                }}
              />
              <Elements.Region className="formkit-subheader" name="subheader">
                <Elements.Content
                  defaults={{
                    content: "Spoken word jazz duo"
                  }}
                />
              </Elements.Region>
            </div>
            <svg
              preserveAspectRatio="none"
              viewBox="0 0 380 316"
              fill="none"
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 315.5V316H380V0.069519C374.289 129.416 320.66 145.831 271.5 156.5C241.629 162.983 215.797 168.599 193.589 173.427C128.807 187.513 94.8691 194.891 81.5 197.5C4 212.622 0 315.5 0 315.5Z"
                style={{
                  fill: svg_color
                }}
              />
            </svg>
          </div>
          <div
            className="formkit-content-container"
            style={styles.forElement("content_container")}
            role="complementary"
          >
            <Elements.Heading
              className="formkit-content-heading"
              name="content_heading"
              tag="h2"
              defaults={{
                content: "Hello"
              }}
            />
            <Elements.Region className="formkit-content" name="content">
              <Elements.Content
                defaults={{
                  content:
                    "We're a spoken word, jazz duo from California. We write, release and perform new music around the country and document the whole process on YouTube and Patreon. Listen to our music and join the journey below."
                }}
              />
            </Elements.Region>
            <div
              className="formkit-accent"
              style={styles.forElement("accent")}
            />
            <Elements.Heading
              className="formkit-form-heading"
              name="form_heading"
              tag="h2"
              defaults={{
                content: "Subscribe"
              }}
            />
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields
                style={{
                  color: checkboxes_color
                }}
              >
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Subscribe" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              className="formkit-disclaimer"
              name="disclaimer"
              defaults={{
                content: "We won’t send you spam. Unsubscribe anytime."
              }}
            />
            <Elements.BuiltWith background="content_container" />
          </div>
        </div>
        <div
          className={`${
            editing ? "formkit-editing-container" : "formkit-container"
          }`}
          role="main"
        >
          <div>
            <div
              style={{
                backgroundColor: styles.forElement("background").backgroundColor
              }}
              className="formkit-bg-color"
            />
            <div
              style={styles.forElement("background")}
              className="formkit-bg-image"
            />
            <div
              className={`${
                editing
                  ? "formkit-region-editing"
                  : "formkit-region-not-editing"
              } formkit-region-content`}
            >
              <Elements.Region
                className="formkit-collection"
                name="collection_content"
              >
                <Elements.Gallery
                  defaults={galleryDefaults.settings}
                  defaultChildren={galleryDefaults.children}
                />
                <Elements.Links
                  defaults={linkDefaults.settings}
                  defaultChildren={linkDefaults.children}
                />
                <Elements.Content
                  defaults={{
                    content: ""
                  }}
                />
              </Elements.Region>
            </div>
          </div>
        </div>
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Rose, { name: "Rose" })
